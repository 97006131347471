const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;

require('./05_colorbox-stenik-modification.min')
require('./11_stenikSelect')
require('./13_jquery.datetimepicker')
require('./14_stenikFilter')
import './07_owl.carousel';

$(function() {
    if(window.key)
    {
        $.ajax({
            url: window.routes.register,
            data: {key: window.key},
            type: 'get'
        }).done(function(res) {
            $.colorbox({
                top: true,
                transition: null,
                stenikAnimations: true,
                overlayClose: false,
                escKey: false,
                html: res
            });
        });
    }
        

    var $document = $(document),
        $window   = $(window);

    $('.timeline .projection').each(function(i, item){
        var img = $(item).find('img');
        if (!img.length) {
            $(item).parent().addClass('noLogos');
        };
    });

    /* Search list
    ------------------------------------------------------- */
    var $searchInput   = $('#search_input'),
        $searchList    = $('#search_list'),
        $searchRequest = null;
    $searchInput.on('keyup', function() {
        if($(this).val().length > 2) {
            if($searchRequest && $searchRequest.readyState != 4) {
                $searchRequest.abort();
            }
            $searchRequest = $.ajax({
                url: window.routes.search,
                type: 'post',
                data: $searchInput.serialize(),
                dataType: 'JSON'
            }).done(function(res) {
                if(res.success) {
                    $searchList.html(res.html);
                    searchListAction('show');
                } else {
                    searchListAction('hide');
                }
            }).fail(function(jqXHR, textStatus, errorThrown) {
                searchListAction('hide');
            });
        } else {
            searchListAction('hide');
        }
    });
    function searchListAction(action) { // action => 'show' || 'hide'
        if(action === 'show') {
            if(!$searchList.hasClass('active'))
                $searchList.addClass('active');
        } else { // 'hide' or anything else
            if($searchList.hasClass('active'))
                $searchList.removeClass('active');
        }
    }

    /* Movies steps filter
    ------------------------------------------------------- */
    var $cinemaSelect   = $('#cinemaSelect'),
        $movieSelect    = $('#movieSelect'),
        $movies         = $movieSelect.find('option:not(.defaultOption)'),
        $projectionTime = $('#projection_time');
    function showHideMovies() {
        var $selected = findSelected('#cinemaSelect');
        if($selected) {
            var cinemaId = $selected.val();
        } else {
            return;
        }
        if(cinemaId && cinemaId.length > 0) {
            // $movies.hide();
            /* NB!
                * IE 7/8 fires error when try to hide select's options!
                * Solution(ugly but works even on IE 6): clear all options and append only the filtered ones
                */
            var $filteredOptions = $movies.prop('selected', false).filter(function() {
                var dat = $(this).data('cinema');
                if(dat.length > 0)
                {
                    var haystack = dat.split(', ')
                }
                else
                {
                    var haystack = []
                }
                if ($.inArray(cinemaId, haystack) == -1) {
                    return false;
                } else {
                    return true;
                }
            });
            $movieSelect.removeAttr('disabled')
                        .find('option:not(.defaultOption)').remove();
            $movieSelect.append($filteredOptions)
                        .find('.defaultOption').prop('selected', true); // reset user's choice

            $projectionTime.attr('disabled', 'disabled');
        } else {
            $movieSelect.attr('disabled', 'disabled')
                        .find('option:not(.defaultOption)').remove();
        }
        // update select beautifier
        $movieSelect.stenikSelect();
    }
    function reverseDate(date) {
        var tempDate = date.split('-');
        var newDate = tempDate[2] + '-' + tempDate[1] + '-' + tempDate[0];
        return newDate;
    }

    showHideMovies();
    $cinemaSelect.on('change', function(){
        showHideMovies();
    });

    var minDate = new Date();
    var maxDate = new Date();
    console.log($movieSelect)
    $movieSelect.on('change', function(){
        var $selectedMovie = findSelected('#movieSelect');
        var $selectedCinema = findSelected('#cinemaSelect');
        if($selectedCinema == null || $selectedMovie == null) {
            return;
        }
        var movieSlug = $selectedMovie.data('movie');
        $.ajax({
            url: "movie/helper/" + movieSlug + '/' + $selectedCinema.val(),
            type: 'POST',
            dataType: 'JSON'
        }).done(function(data){
            if (data.success == false) {
                $.colorbox({
                    top: true,
                    transition: null,
                    stenikAnimations: true,
                    html: '<div class="popup"><strong class="red">' + data.message + '</strong></div>'
                });
            } else {
                var datesArr = data.message;
                var dates = [];
                var min = null;
                var max = null;
                if (datesArr.length > 0) {
                    max = datesArr[datesArr.length - 1];
                    min = datesArr[0];

                    $projectionTime.removeAttr('disabled');
                    $projectionTime.datetimepicker({
                        lang:'bg',
                        format: 'd-m-Y', // Format datetime
                        formatDate: 'd-m-Y', // Format date for minDate and maxDate
                        timepicker: false,
                        minDate: min,
                        maxDate: max,
                        scrollInput: false,
                        onChangeDateTime:function(dp,$input){
                            if (dp != null) {
                                var dateStr = reverseDate(dp.toISOString().slice(0,10));
                                $.ajax({
                                    url: window.routes.movie_url,
                                    data: {'movieSlug': $selectedMovie.data('movie'), 'date': dateStr, 'cinemaId': $selectedCinema.val()},
                                    method: "POST",
                                    dataType: "JSON"
                                }).done(function(data){
                                    document.location.href = data.message;
                                });
                            };
                        }
                    });
                } else {
                    $projectionTime.attr('disabled', 'disabled');
                }
            }
        }).fail(function(data){
            $.colorbox({
                fixed: true,
                top: true,
                transition: null,
                stenikAnimations: true,
                html: "<div class=\"popup\"><strong class=\"red\">"+window.unexpected_error+"</strong></div>"
            });
        });
    });

    /* Top slider
    ------------------------------------------------------- */
    var $topSlider      = $('#top_slider').find('.sliderWrapper'),
        $topSliderItems = $topSlider.children('.slideItem');

    $topSlider.on('change.owl.carousel', function(event){
        $('.videoActive .videoClose').trigger('click');
    });
    // Init
    var topSliderOptions = {
        loop: ($topSliderItems.length > 1)? true : false,
        items: 1,
        nav: true,
        lazyLoad: true,
        dots: false,
        navRewind: true,
        autoplay: true,
        autoplayTimeout: 8000,
        smartSpeed: 600
    }
    if(_stenikMain.isMobile()) {
        var responsiveOptions = {
            fullWidth: true,
            responsive: {
                0: {
                    autoHeight: true
                }
            }
        };
        topSliderOptions = $.extend({}, topSliderOptions, responsiveOptions);
    }
    $topSlider.owlCarousel(topSliderOptions);
    // Youtube -> Slider synchronization
    var isPlaying = false;
    $('body').on('stenikPlayerActive', function() {
        $topSlider.trigger('pause.owl.autoplay');
        if ($('#top_slider').find('.sliderWrapper').data('owlCarousel')) {
            $('#top_slider').find('.sliderWrapper').data('owlCarousel').state.videoPlay = true;
        }
        isPlaying = true;
    });
    $('body').on('stenikPlayerInactive', function() {
        $topSlider.trigger('continue.owl.autoplay');
        if ($('#top_slider').find('.sliderWrapper').data('owlCarousel')) {
            $('#top_slider').find('.sliderWrapper').data('owlCarousel').state.videoPlay = false;
        }
        isPlaying = false;
    });
    // Toggle slider autoplay on hover
    $topSlider.hover(function() {
        $topSlider.trigger('pause.owl.autoplay');
    }, function() {
        if(!isPlaying) {
            $topSlider.trigger('continue.owl.autoplay');
        }
    });

    /* Highlight sliders
    ----------------------------------------------------------- */
    var $highlightSlider = $('.highlightSlider').find('.sliderWrapper');
    $highlightSlider.owlCarousel({
        items: 1,
        nav: false,
        dots: true,
        autoplay: false,
        smartSpeed: 500
    });

    /* Filter init
    ----------------------------------------------------------- */
    $('.stenikFilter').stenikFilter({
        // defaultFilter: 'all'
        defaultFilter: $('.stenikFilter').find('.filter').eq(0).data('filter-anchor')
    });

    /* Movies layout toggle
    ------------------------------------------------------- */
    $('.classToggle').each(function(i, wrapper){
        var $toggleWrapper = jQuery(wrapper),
            $classOn  = $toggleWrapper.find('.toggle-on'),
            $classOff = $toggleWrapper.find('.toggle-off');

        var $parent = $toggleWrapper.parent();
        var classToggle = $toggleWrapper.data('class-toggle');

        $classOn.click(function() {
            $window.trigger('stenik.scrollRevealUpdate');

            $parent.addClass('classToggling').delay(250).queue(function() {
                $(this).removeClass('classToggling').addClass(classToggle).dequeue();
                $window.trigger('stenik.scrollRevealUpdate.after');
            });

            classToggleCallback('on');
        });
        $classOff.click(function() {
            $window.trigger('stenik.scrollRevealUpdate');

            $parent.addClass('classToggling').delay(250).queue(function() {
                $(this).removeClass('classToggling').removeClass(classToggle).dequeue();
                $window.trigger('stenik.scrollRevealUpdate.after');
            });

            classToggleCallback('off');
        });
    });
    function classToggleCallback(status) {
        // keep the layout type in a cookie
        if(status === 'on') {
            var nowDate = new Date();
            _stenikMain.setCookie('moviesLayout', 'small', nowDate.setMonth(nowDate.getMonth() + 1) );
        } else {
            _stenikMain.deleteCookie('moviesLayout');
        }

        $window.trigger('updateScroller.stenik');
        setTimeout(function() {
            $('.movieBox').find('.title').trigger('update'); // update dotdotdot
        }, 200);
    }

    /* Moviebox timeline scrollbars
    ----------------------------------------------------------- */
    $window.on('load', function() {
        var $timelineSets = $('.movieBox').find('.timelineSet');
        $timelineSets.each(function(i, set) {
            var $set       = jQuery(set),
                $timeline  = $set.find('.timeline'),
                $scrollbar = $set.find('.timelineScrollbar'),
                $clock     = $scrollbar.find('.handle .mousearea'),
                scrollInst = null;
            var options = {
                speed: 350,
                scrollBy: 1,
                itemNav: 'basic',
                horizontal: true,
                scrollTrap: true,
                clickBar: true,
                dragHandle: true,
                dynamicHandle: false,
                scrollTrottle: 200,
                elasticBounds: true,
                mouseDragging: true,
                touchDragging: true,
                scrollBar: $scrollbar
            }
            var $timeItems = $timeline.find('.item');
            if($timeline.length) {
                scrollInst = new Sly($timeline, options).init();
                scrollInst.on('moveStart', function() {
                    if(!$set.hasClass('scrollActive')) {
                        $set.addClass('scrollActive');
                        $timeItems.removeClass('hover');
                    }
                });
                scrollInst.on('moveEnd', function() {
                    $set.removeClass('scrollActive');
                });
                scrollInst.on('change', function() {
                    if(this.pos.cur > this.pos.dest) {
                        TweenLite.to($clock, 0.6, {rotation: "-=90" });
                    } else {
                        TweenLite.to($clock, 0.6, {rotation: "+=90" });
                    }
                });
                $window.on('stenik.scrollRevealUpdate.after', function() {
                    scrollInst.reload();
                });
            }
        });
    });
    /* Datepickers
    ----------------------------------------------------------- */
    // Projection time (filter bar)
    // $projectionTime.datetimepicker({
    //     lang:'bg',
    //     format: 'd.m.Y', // Format datetime
    //     formatDate: 'd.m.Y', // Format date for minDate and maxDate
    //     timepicker: false,
    //     minDate: minDate.getDay() + '-' + minDate.getMonth() + '-' + minDate.getFullYear(),
    //     maxDate: maxDate.getDay() + '-' + maxDate.getMonth() + '-' + maxDate.getFullYear(),
    //     scrollInput: false
    // });
    // User's birth date (registration popup)
    // $document.on('cbox_complete', function() {
    //     $('.datepicker input').datetimepicker({
    //         lang:'bg',
    //         format: 'd.m.Y',
    //         formatDate: 'd.m.Y',
    //         timepicker: false,
    //         minDate: '01.01.1945',
    //         maxDate: '12.12.2015',
    //         scrollInput: false
    //     });
    // });
})
function findSelected(element) {
var el = null
$(element).children().each(function(){
    if($(this).attr('selected') == 'selected') {
        el = $(this);
    }
});
return el;
}